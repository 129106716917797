/*
    Created on : Sep 16, 2022, 11:35:43 PM
    Author     : AWD
*/

@font-face
{
    font-family:Font-04;
    src:url(fonts/AdventPro-Regular.ttf);
    font-display: swap;
}
$color-00:black;
$color-01: rgba(0, 0, 0, .86);
$color-01-1: rgba(0, 0, 0, .6);
$color-02: rgb(3,57,108);
$color-03:#63aae7;
$color-04:whitesmoke;
$color-05:white;
$color-06:transparent;



$App-main-menu-background: $color-01;
$App-main-menu-btn: $color-03;
$App-main-menu-btn-back: $color-06;
$App-main-menu-link-color: $color-05;
$App-main-menu-link-background: $color-06;
$App-main-menu-link-color-hover:$color-05;
$App-main-menu-link-background-hover: $color-02;

$App-main-header-background-mask:$color-01-1;
$App-main-header-color: $color-05;
$App-main-header-h1-color:$color-03;

$App-slave-background:transparent;
$App-slave-color: $color-00;
$App-slave-h2:$color-02;

$App-main-footer-background:$color-00;
$App-main-footer-color:$color-03;

$App-btn-padding: 13px;
$App-btn-color: $color-05;
$App-btn-background:$color-02;
$App-btn-border-width: 1px;
$App-btn-border-style: solid;
$App-btn-border-color: $color-06;
$App-btn-txt-decoration: none;
$App-btn-justify-content:center;
$App-btn-color-hover: $color-05;
$App-btn-background-hover:$color-03;
$App-btn-border-width-hover: 1px;
$App-btn-border-style-hover: solid;
$App-btn-border-color-hover: $color-06;

$go-to-top-color:$color-03;
$go-to-top-background: $color-06;
$go-to-top-shadow: 2px 2px 5px 1px $color-03, -2px -2px 5px 1px $color-03;
$go-to-top-radius: 2px;



html {
    scroll-behavior: smooth;
    scrollbar-color: $color-03 $color-00;
    scrollbar-width: thin;
}
body{
    font-family: Font-04;  
    font-size: 1.2rem;
    
}
*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}
.blur-img{
   transition: 4s all;
   filter:blur(0px)  !important;
   
   
}
//------------------------------------------------------------------------------
/////////////////////////////////
///////// Main Menu /////////////
////////////////////////////////
.App-main-menu{
    position: fixed;
    z-index: 11;
    left: 0;
    right: 0;
    height: 80px;
    display: flex;
    flex-wrap: wrap;
    background: $App-main-menu-background;
    padding: 10px;
    /*
    box-shadow: 1px 1px 13px 1px grey;
    */
    transition: height 1s;
    > a {
        flex: 50%;
        display: flex;
        > svg{
            width: 60px;
            height: 60px;
            background: $App-main-menu-btn-back;
            transition: all 1s;
            
            box-shadow: 2px 2px 5px 1px $App-main-menu-btn, -2px -2px 5px 1px $App-main-menu-btn;
            border-radius: 2px;
            > path{
                color: $App-main-menu-btn!important;
            }
        }
        &:nth-child(2){
            justify-content: right;
        }
    }
    > ul{
        flex: 100%;
        background: rgba(0, 0, 0, 0);
        transform: translateX(100%) translateY(-100%);
        transition: 1s all;
        > li{
            transition: 1s all;
            transform: rotateX(90deg);
            display: flex;
            > span{
                flex: 0 1 auto;
            }
            a {
                flex: 1 0 auto;
                display: flex;
                justify-content: center;
                padding: 13px;
                font-size: 1.2em;
                position: relative;
                text-decoration: none;
                transition: all .4s;
                color: $App-main-menu-link-color!important;
                background: $App-main-menu-link-background;
            }
            a:before, a:after {
                position: absolute;
                content: '';
            }
            a:after {
                height: 5px;
                left: 50%;
                right: 50%;
                transition: all .4s;
                bottom: 0;
                z-index: -2;
                transition: all 1s;
                width: 0%;
                
            }
            a:hover, > a.active{
                transition: all .4s;
                color: $App-main-menu-link-color-hover!important;
                background: $App-main-menu-link-background-hover;
                border-bottom: 1px solid $App-main-menu-link-color-hover!important;
                &.active{
                    color: $App-main-menu-link-color-hover!important;
                }
                &:after{
                    width: 100%;
                    left: 0;
                    right: 0;
                    height: 100%;
                    transition: all .4s;
                    z-index: -2;
                    border-bottom: 1px solid $App-main-menu-link-color-hover;
                }
            }
        }
    }
    &.open-main-menu{
        transition: height 1s;
        height: 100%;
        background: $App-main-menu-background;
        > a{
            > svg{
                transition: all 1s;
                transform: rotateX(180deg) rotateY(180deg);
            }
        }
        > ul{
            transition: all .5s;
            transform: translate(0);
            background: $App-main-menu-background;
            overflow: scroll;
            display: flex;
            flex-direction: column;
            height: 80%;
            > li{
                transition: .5s .5s all;
                transform: rotateX(0deg);
            }
        }
    }
}
/////////////////////////////////
///////// Main Menu /////////////
////////////////////////////////
//------------------------------------------------------------------------------
/////////////////////////////////
///////// Main Header /////////////
////////////////////////////////
.App-main-header{
    overflow: hidden;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(6,1fr);
    height: 100vh;
    > figure, > div{
        grid-column: 1/7;
        grid-row: 1 / 7;
        height: 100vh;
    }
    > figure > img {
        filter:blur(5px);
        transition: all 2s;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    > div{
        background: $App-main-header-background-mask;
        z-index: 1;
        display: flex;
        flex-direction: column;
        gap: 15px;
        align-items: start;
        justify-content: center;
        padding: 10px;
        > *{
            display: flex;
            flex-direction: column;
            justify-content: center;
            
            padding: 10px;
        }
        > .mh-left{
            gap: 5px;
        }
        > .mh-right{
            color: $App-main-header-color;
            > div{
                display: flex;
            flex-direction: column;
            justify-content: center;
           
            padding: 10px;
                color: $App-main-header-color;
            }
            p{
            }
            >  h1, > h2{
                color: $App-main-header-h1-color;
                > a{
                    color:$App-main-header-h1-color;
                }
            }
            > ul, p{
                color: white;
            }
            > ul{
                > li{
                   > a{
                       color: $App-main-header-h1-color;
                       line-height: 1.4;
                   }
                }
            }
        }
    }
}
/////////////////////////////////
///////// Main Header /////////////
////////////////////////////////
//------------------------------------------------------------------------------
/////////////////////////////////
///////// Main slave article /////////////
////////////////////////////////
.App-slave-article{
    background: $App-slave-background;
    color: $App-slave-color;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 65px 10px 10px;   
    > *{
        flex: 1 0  100%;
        height: 100%;        
    }
    > header{        
        order: 1;
        color:black;
        gap: 10px;
        > h2{
        color: $App-slave-h2;
        }
        > div{
            display: flex;
            flex-direction: column;
            gap: 10px;
        }
    }
    > figure{
        order: 2;
        overflow: hidden;   
        height: auto;
        > img{
            width: 100%;
            height: 100%;
            object-fit: cover;
            overflow: hidden;
            transition: .4s all;
            &:hover{
                transform: scale(1.1) rotateZ(-2deg);
                transition: 1s all;
            }
        }
    }
    &:nth-child(odd){
        > figure{
            > img{
                &:hover{
                    transform: scale(1.1) rotateZ(2deg);
                    transition: 1s all;
                }
            }
        }
    }
}
/////////////////////////////////
///////// Main slave article /////////////
////////////////////////////////
//------------------------------------------------------------------------------

.App-blog{
    width: 100%;
    padding: 20px;
    background: lightgray;
    > ul{
        display: flex;
        flex-wrap: wrap;
        gap: 5px;
        background: grey;
        > li{
            flex: 0 0 100%;
          
            list-style: none;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 20px;
            gap: 20px;
            
            > h2, p, ul{
                text-align: center;
                > a{
                    color:#63aae7;
                }
            }
            > h2{
                margin-bottom: auto;
            }
             transition:all 1s;
            &:hover{
                transition:all 1s;
                background: #eee;
            }
        }
    }
    
    @media all and (min-width:1024px){   
    padding-left: 100px;
    padding-right: 100px;
    > ul{
        >li{
            flex: 0 0 calc((100% - 5px)/2);
        }
    }
    }
    
    @media (min-width: 1324px){   
    padding-left: 200px;
    padding-right: 200px;
    > ul{
        >li{
            flex: 0 0 calc((100% - 10px)/3);
        }
    }
    }
}






/////////////////////////////////
///////// Main Footer /////////////
////////////////////////////////

.App-main-footer{
    display: flex;
    flex-direction: column;
    background: $App-main-footer-background ;
    padding-top: 80px;
    padding-bottom: 105px;
    box-shadow: -1px -1px 13px 1px $color-03;
    > div{
        display: flex;
        flex-direction: column;
        justify-content: end;
        gap: 3px;
        color: $App-main-footer-color;
        padding: 20px;
        > nav, > div{
            display: flex;
            justify-content: center;
            
            
        }
        > nav{
            > a{
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 5px;
            }
        }
        > div{
            flex-direction: column;
            > figure{
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }
        }
    }
}
/////////////////////////////////
///////// Main Footer /////////////
////////////////////////////////
//------------------------------------------------------------------------------
/////////////////////////////////
///////// Main BTNS /////////////
////////////////////////////////

.App-btn{
    text-decoration: $App-btn-txt-decoration;
    padding: $App-btn-padding;
    color: $App-btn-color;
    background: $App-btn-background;
    border-width: $App-btn-border-width;
    border-style: $App-btn-border-style;
    border-color: $App-btn-border-color;
    display: flex;
    justify-content: $App-btn-justify-content;
    transition: 1s all;
    &:hover{
        color: $App-btn-color-hover;
        background: $App-btn-background-hover;
        transition: 1s all;
    }
}

.go-to-top{
    display: flex;
    width: 60px;
    height: 60px;
    position: fixed;
    right: 10px;
    bottom: 10px;
    color: $go-to-top-color;
    background: $go-to-top-background;
    box-shadow: $go-to-top-shadow;
    border-radius: $go-to-top-radius;
    opacity: 0;
    transition: all 2s;
    padding: 5px;
    
    > svg{
        border-radius: .5em;
        width: 100%;
        height: 100%;
    }
    &.show-up{
        transition: all 2s;
        opacity: 1;
        z-index: 1300;
    }
}
/////////////////////////////////
///////// Main BTNS /////////////
////////////////////////////////



//-- MEDIA MIN WIDTH 800 ----------------------------------------------------------
@media all and (min-width:800px){
    /////////////////////////////////
///////// Main Header /////////////
////////////////////////////////
    .App-main-header{
        > div{
            flex-direction: row;
            > *{
                height: 100%;
                > *{
                    padding-top: 10px;
                    padding-bottom: 10px;
                }
            }
            > :nth-child(1){
                > figure{
                    display: initial;
                }
                border-bottom: none;
                flex: 0 0 30%;
                text-align: center;
            }
            > :nth-child(2){
                flex:1 0 70%;
            }
        }
    }
/////////////////////////////////
///////// Main Header /////////////
//////////////////////////////// 
//------------------------------------------------------------------------------
/////////////////////////////////
///////// Main Slave article /////////////
//////////////////////////////// 
.App-slave-article{
        >header{
            padding: 10px;
            display: flex;
flex-direction: column;
justify-content: center;

height: auto;
        }
        > *{
            flex:1 0 50%;
        }
        &:nth-child(odd){

            > header{
                order: 2;
                text-align: left;
            }
            > figure{
                order: 1;
            }
        }
    }
/////////////////////////////////
///////// Main Slave article /////////////
//////////////////////////////// 
//------------------------------------------------------------------------------
/////////////////////////////////
///////// Main Footer /////////////
////////////////////////////////
    .App-main-footer{
        flex-direction: row;
        > div{
            flex: 50%;
        }
    }
/////////////////////////////////
///////// Main Footer /////////////
////////////////////////////////
//------------------------------------------------------------------------------
}


@media all and (min-width:1024px){   
    /////////////////////////////////
///////// Main Menu /////////////
////////////////////////////////
    .App-main-menu{
        padding-left: 100px;
        padding-right: 100px;
        height: 60px;
        background: $App-main-menu-background;
        > a {
            flex: 0 1 auto;
            > figure{
                img{
                    transform: scale(1.2);
                }
            }
            &:nth-child(2){
                display: none;
            }
        }
        > ul{
            flex: 1 0 auto;
            transform: translateX(0);
            transition: 1s all;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: center;
            gap: 3px;
            > li{
                transform: rotateX(0deg);
            }
        }
    }
    /////////////////////////////////
///////// Main Menu /////////////
////////////////////////////////
 .App-main-header{
        > div{

            padding-left: 100px;
            padding-right: 100px;
        }

    }
    .App-section-services{
        
        padding-left: 100px;
        padding-right: 100px;
    }
    .App-main-footer{

        padding-left: 100px;
        padding-right: 100px;

    }
    .App-slave-article{
        padding-left: 100px;
        padding-right: 100px;
    }
}

@media all and (min-width:1324px){
    body{
        font-size: 1.4rem;
    }
    p{
       /* max-width: 776px!important; */
    }
    .App-main-menu{
        padding-left: 200px;
        padding-right: 200px;

    }
    /////////////////////////////////
    ///////// Main Menu /////////////
    ////////////////////////////////
    .App-main-header{
        > div{

            padding-left: 200px;
            padding-right: 200px;
        }

    }
    .App-section-services{
        
        padding-left: 200px;
        padding-right: 200px;
    }
        .App-slave-article{
        padding-left: 200px;
        padding-right: 200px;
    }
    .App-main-footer{

        padding-left: 200px;
        padding-right: 200px;

    }
}